$medium-width: 1200px;
$small-width: 550px;

$header-height: 80px;

$background-color: rgb(9, 33, 50);
$footer-font-color: mix($background-color, white, 50%);


html, body, #root, .app, .wrapper {
  height: 100%;
}

body {
  background-color: $background-color;
}

body {
    overflow-y: scroll;
}

.app {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;

  color: white;

  .wrapper {
    @media only screen and (min-width: $small-width + 1) {
      padding: 0 50px;
      .video-container>h1 {
        font-size: 40px;
      }
    }
    @media only screen and (max-width: $small-width) {
      padding: 0 20px;
      .video-container>h1 {
        font-size: 30px;
      }
    }

    //height: 101%;

    width: 100%;

    display: flex;


    flex-direction: column;
    justify-content: space-between;

    .page-content {
      display: flex;
      padding-top: 80px;

      @media only screen and (max-width: $medium-width) {
        flex-direction: column;
      }

      .video-container {
        margin: 0 15px;

        h1 {
          height: $header-height;
          margin: 0px;
        }

        span {
          display: block;
        }

        width: 100%;
      }

      .list-container {

        display: flex;

        @media only screen and (max-width: $small-width) {
          flex-direction: column;
        }

        .list {
          margin: 0 15px;

          @media only screen and (min-width: $medium-width + 1) {
            width: 250px;
          }
          @media only screen and (max-width: $medium-width) {
            flex-grow: 1;
          }

          >h3 {
            height: $header-height;

            font-size: 15px;
            margin: 0px;
          }
        }
      }
    }
  }


  .page-footer {
    padding: 10px 0;
    color: $footer-font-color;
    span:not(:last-child):after {
      content: '|';
      padding: 0 10px;
    }
    a {
      color: darken($footer-font-color, 10%);
      text-decoration: none;
      font-weight: bold;
      &:hover {
        color: darken($footer-font-color, 20%);
        text-decoration: underline;
      }
    }
  }
}

.list-item-container {
  display: flex;
  margin-bottom: 20px;

  h3 {
    margin: 0;
    font-size: 14px;
  }
  span {
    font-size: 14px;
    display: block;
  }

  img {
    $img-size: 60px;

    width: $img-size;
    height: $img-size;
    margin-right: 20px;
    display: block;
  }
}

